<template>
    <div  class="screen-wrap" id="screen-wrap">
      <div id="returnQuiz" class="complete" >
          
      </div>
    </div>
    
    <div v-if="record != null" class="wrap" :style="'padding:0; margin:0;'+ record.bodyStyle">

      <paper-layout v-if="record != null" layoutCategory="quiz" id="layout" :layout="record.layout" :data="record.item" :record="record" :models="record.item.attributes" :parent="publicOrganization"/>

    </div>
    
</template>
<script>
  let tokenQuote = null
  
  //Tools
  
  import { API, Storage, Auth} from 'aws-amplify'
  import Vuex from "vuex";
  import Swal from 'sweetalert2'
  import { loading} from '../../store/tools'
  
  //import html2pdf from 'html2pdf'
  //Components
  import PaperDisplayer from '../../components/papers/PaperDisplayer.vue'
  import PaperLayout from '../../components/papers/PaperLayout.vue'
  import UiButton from '../../components/ui/UiButton.vue';
  //GRAPHQL
  import { smd_updateSmartQuiz } from '../../graphql/mutations'
  import { smd_getSMLItem, smd_getSMLItemByName  } from '../../graphql/queries'

  export default {
    name:'checkout',
    components:{
      PaperDisplayer,
      UiButton,
      PaperLayout,
    },
    data() {
      return {
        record:null,
        response: null,
        
      }
    },
    created() {
      this.setPublicOrganization(undefined)
      this.setPublicOrganizationData(undefined)
      loading('show')
      this.setUserWeb()
      /*if (this.reload == true){
        this.setStatusReload(false);
        location.reload();
      }*/
      
    },
    methods: {
      ...Vuex.mapActions(['getPublicOrganization','getPublicOrganizationData']),
      ...Vuex.mapMutations(['setStatusReload','setPublicOrganizationData','setPublicOrgPay','setPublicOrganization','setContactSK','setWebSite','SetUser']),

      close(){
        window.open(location.href, "_self", "");
        window.close()
      },
      //View
      async setUserWeb(){
        loading('show')
        this.record = null
        console.log('>>> USER: '+this.user);
        Auth.signIn('smd_webuser', '383@JDU45ss#')
        .then((user) => { 
            this.SetUser(user.username)
            this.getItem()
        })
        .catch((err) => popUp(pullData.smd_response, 'response', 'err'));
        loading('hidde')
        
        
      },
      async getItem(   ) { 
        loading('show', 'getItem')
        
        //console.clear()
        let pullData = ''
        let ORGPK = this.publicOrganization
        let SMLPK = this.publicOrganization
        console.log(this.$router.currentRoute.value.query);
        if (this.$router.currentRoute.value.query.ORGPK != undefined) {
          ORGPK = this.$router.currentRoute.value.query.ORGPK
          this.getPublicOrganization()
        }else{
          console.log('Here begin ALIAS');
          console.time()
          await this.getPublicOrganization()//Alias
          console.timeEnd()
          ORGPK = this.publicOrganization
        }
        if (this.$router.currentRoute.value.query.SMLPK != undefined) {
          SMLPK = this.$router.currentRoute.value.query.SMLPK
        }else{
          SMLPK = ORGPK
        }
        let query = null
        console.log('Here begin GET QUIZ');
        console.time()
        if (this.$router.currentRoute.value.query.quiz) {
          console.log('CON NOMBRE');
          try {
            query = {
              itemPK: ORGPK,
              itemSK: this.$router.currentRoute.value.query.SK,
              SMLPK: SMLPK,
              layoutName: this.$router.currentRoute.value.query.quiz,
              parent: undefined,
              entity: {PK: '#SMARTDASH', SK: '#ENT#961aa971-3017-4f13-8f52-0b3d513313a3'}
            }
            
            console.log(query);
            pullData = await API.graphql({ 
              query: smd_getSMLItemByName,
              variables: query
            }); 
            
            pullData = pullData.data.smd_getSMLItemByName
  
            //Proccess Data
            pullData.layout =  JSON.parse(pullData.data)
            pullData.item =  JSON.parse(pullData.item)
            pullData.attStr =  JSON.stringify(pullData.item.attributes)
            let SML = {}
            let SMLData =JSON.parse(pullData.SML).attributes
            for (const key in SMLData) {
              try {
                if (SMLData[key].attributeType == 'L') {
                  SMLData[key].attributeValue =JSON.parse(SMLData[key].attributeValue)
                }
              } catch (error) {
              }
              SML[SMLData[key].attributeName] =SMLData[key].attributeValue
            }
            delete pullData.data
            this.record = Object.assign(pullData, SML) 
            console.log(this.record);
            console.log(this.publicOrganizationData);
            this.setWebSite(this.record)
            document.head.insertAdjacentHTML("beforeend", this.record.fontScript)
          } catch (error) {
            
            console.log(error);
          }
        } else {
          console.log('CON SK');
          try {
            query = {
              itemPK: ORGPK,
              itemSK: this.$router.currentRoute.value.query.SK,
              SMLPK: SMLPK,
              SMLSK: this.$router.currentRoute.value.query.SMLSK,
              parent: undefined,
              entity: {PK: '#SMARTDASH', SK: '#ENT#961aa971-3017-4f13-8f52-0b3d513313a3'}
            }
            console.log(query);
            pullData = await API.graphql({
              query: smd_getSMLItem,
              variables: query
            }); 
            pullData = pullData.data.smd_getSMLItem
            console.log("--> Data of Query Before :", pullData);
            //Proccess Data
            pullData.layout =  JSON.parse(pullData.data)
            pullData.item =  JSON.parse(pullData.item)
            
            console.log(pullData.item.attributes)
            pullData.attStr =  JSON.stringify(pullData.item.attributes)
            console.log("--> Data of Query After :", pullData);
            delete pullData.data
            let SML = {}
            let SMLData =JSON.parse(pullData.SML).attributes
            for (const key in SMLData) {
              try {
                if (SMLData[key].attributeType == 'L') {
                  SMLData[key].attributeValue =JSON.parse(SMLData[key].attributeValue)
                }
              } catch (error) {
              }
              SML[SMLData[key].attributeName] =SMLData[key].attributeValue
            }
            delete pullData.data
            this.record = Object.assign(pullData, SML) 
            this.setWebSite(this.record)
            document.head.insertAdjacentHTML("beforeend", this.record.fontScript)
          } catch (error) {
            console.log(error);
          }
        }
        console.log(this.record);
        document.getElementById('title').innerText = this.record['smartlayout.name']
        document.getElementById('favicon').href = this.organizationData.favicon[0].url
        document.getElementById('metaTitle').content = this.record['smartlayout.description']
        console.log(document.getElementById('metaTitle'));
        console.timeEnd()
        this.sub= false
        loading('hidde', 'getItem')
      },
      //Complete

    },
    computed:{
      ...Vuex.mapState(['publicOrganization', 'publicOrganizationData','publicOrganization', 'reload', 'user','publicOrgPay','dataUser','quiz','organizationData','publicOrganizationDataObj'])
    }
  }
</script>
<style lang="less">
  .screen-wrap{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #00000099;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    &.show{
      z-index: 999;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -ms-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }
    .complete{
      width: 900px;
      //height: 400px;
      margin: 0 auto;
      text-align: center;
      padding: 20px;
      border-radius: 10px;
      background: #ffffff;
      display: block;
      border-radius: 10px;
      box-shadow: 0px 0px 13px 4px rgba(0,0,0,0.49);
      -webkit-box-shadow: 0px 0px 13px 4px rgba(0,0,0,0.49);
      -moz-box-shadow: 0px 0px 13px 4px rgba(0,0,0,0.49);
      .ico{
        width: 100%;
        margin: 10px 0;
        svg{
          width: 200px;
          height: 100px;
          display: block;
          margin:0 auto;
        }
      }
      h5{
        margin: 10px 0 40px 0;
      }
      button{
        outline: none;
        cursor: pointer;
        font-weight: 500;
        line-height: inherit;
        position: relative;
        text-decoration: none;
        text-align: center;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        -webkit-appearance: none;
        -moz-appearance: none;

        border: none!important;
        width: 200px;
        background: #22af18;
        padding: 12px;
        color: #fff;
        border-radius: 10px;
        margin: 0 0 5px 0;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover{
          background: #238a1c;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
        &:focus{
          outline: none;
        }
      }
    }
  }
</style>